@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
html {
  scroll-behavior: smooth;
}
.OurFeatures2 div::-webkit-scrollbar {
  display: none;
}

.navbartext a {
  font-size: 18px;
  color: #2a2a2a;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.245);
  padding: 0px 20px;
  font-family: "Montserrat";
}
.msgtwolevel p{
  font-size: 12px;
  text-align: center;
}

.signup button {
  padding: 15px 30px;
  display: flex;
  align-items: center;
}

.banner-1 {
  background-image: url("../images/bg-1.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
}

.banner-1>p:nth-child(1) {
  font-size: 20px;
  font-family: "Crimson Pro";
}

.banner-1>p:nth-child(3) {
  font-size: 20px;
  width: 80%;
  font-family: "Nunito Sans";
}





.banner-1>p:nth-child(4) {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 20px;
  padding-top: 20px;

}

.banner-1>img:nth-child(1) {
  width: 220px;
  height: 66px;
  /* object-fit: cover; */
}

.banner-1 a {
  margin-top: 30px;
  width: 164px;
  height: 53px;
  top: 1598px;
  left: 150px;
  padding: 4px 31.86px 9px 32px;
  gap: 0px;
  font-family: "Crimson Pro";
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  background: #171717;
  color: white;
}

.banner-2-mini1 {
  background-color: #8ee3bb;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 90px;
  height: 100%;
}

.banner-2-mini1 h3 {
  font-family: "Montserrat";
  font-size: 37px;
  font-weight: 600;
}

.banner-2-mini1 p {
  width: 317.16px;
}

.banner-2-mini1 a {
  font-family: "Crimson Pro";
  font-size: 21px;
  font-style: italic;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  display: flex;
  align-items: center;
}

.banner-2-mini2 {
  background-color: #ffd0ba;
}

.oneline {
  background-color: black;
  overflow-y: auto;
  white-space: nowrap;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.oneline p:before {
  content: "•";
  color: #ffffff;
  font-weight: 1000;
}

.OurFeatures {
  background-color: #8ee3bb;
  overflow: hidden;
  padding: 80px;
}


.OurFeatures2 span {
  font-family: "Crimson Pro";
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
}

.OurFeatures2 p:nth-child(2) {
  padding-top: 10px;
  font-family: "Montserrat";
  font-size: 23px;
  font-weight: 600;
  line-height: 29.9px;
  letter-spacing: -0.11500000208616257px;
  text-align: left;
}

.OurFeatures2 p:nth-child(3) {
  padding-top: 10px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 30.06px;
  text-align: left;
}

.OurFeatures2 p:nth-child(4) {
  font-family: "Crimson Pro";
  font-size: 21px;
  font-style: italic;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  display: flex;
  align-items: center;
}

.OurFeatures2 div div {
  padding-left: 40px;
  border-right: 1px solid rgba(0, 0, 0, 0.329);
}

.OurFeatures2 div div:nth-child(3) {
  border-right: none;
}

.linehr {
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.218);
  position: relative;
  top: 30px;
}

.testimonialscontent p {
  padding-top: 60px;
  width: 641px;
  height: 164px;
  top: 2975px;
  left: 950px;
  gap: 0px;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 40.8px;
  text-align: left;
}

.testimonialscontent div {
  padding-top: 80px;
}

.testimonialscontent .testiname {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1.399999976158142px;
  text-align: left;
}

.testimonialscontent .testiname span {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1.399999976158142px;
  text-align: left;
}

.testimonialsimage {
  top: 90px;
  position: relative;
  z-index: 1 !important;
  margin-bottom: 20px;
}


.swipercontainer {
  position: relative;
}

.feedbox {
  top: 60px;
  background-color: #ffe399;
  width: 386px;
  height: 348px;
  bottom: 200px;
  left: 0px;
  padding: 40.31px 86px 254.16px 44px;
  gap: 0px;
  position: absolute;
  z-index: 0;
  font-family: "Montserrat";
  font-size: 50px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.25px;
  text-align: left;
}

/* custom-pagination.css */

.mySwipper1 .swiper-pagination-bullet {
  background-color: #071c1f;
  border-radius: 100%;
  padding: 7px;
}

.mySwipper1 .swiper-pagination-bullet-active {
  background-color: #000000;
}

.cardcontent .minitext {
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.cardcontent .minitext span {
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #666666;
  padding-left: 20px;
}

.mainbox {
  width: 100%;
  display: flex;
}

.minibox1 {
  width: 30%;
  background-image: url("../images/ima (2).png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.minibox3 {
  width: 30%;
}

.minibox2 {
  width: 70%;
}

.cardcontent h2 {
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}

.cardcontent p {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 29.71px;
  text-align: left;
}

.cardcontent button {
  width: 135px;
  height: 50px;
  top: 4209.41px;
  left: 239px;
  gap: 0px;
  border-radius: 4px;
  background: #000000;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  color: white;
}

.ourblogs {
  background-color: #ffe399;
}

.ourblogs h2:nth-child(1) {
  font-family: "Montserrat";
  font-size: 50px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.25px;
  text-align: left;
  text-align: center;
  padding-top: 40px;
}

.ourblogspara {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 29.71px;
  text-align: center;
  padding-bottom: 30px;
}

.ourblogsmsg {
  width: 101px;
  height: 24px;
  padding: 1px 15px 0px 15px;
  gap: 0px;
  border-radius: 4px;
  background: #ffe399;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: 700;
  line-height: 22.29px;
  text-align: left;
  position: absolute;
  top: 20px;
  left: 10px;
}

.topscroller {
  width: 54px;
  height: 54px;
  padding: 18.03px 0px 18.03px 0px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  background: #000000;
  position: absolute;
  right: 0px;
}

.aboutus h2 {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.25px;
  text-align: left;
}

.aboutus p {
  padding-top: 20px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 30.06px;
  
}


.aboutus a {
  margin-top: 30px;
  width: 164px;
  height: 53px;
  top: 1598px;
  left: 150px;
  padding: 4px 31.86px 9px 32px;
  gap: 0px;
  font-family: "Crimson Pro";
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  background: #171717;
  color: white;
}

.footercontent {
  border-top: 1px solid #d1d1d1;
  padding-top: 30px;
  padding-bottom: 40px;
}

.footercontent h6 {
  font-family: "Montserrat";
  font-size: 19px;
  font-weight: 600;
  line-height: 23.75px;
  letter-spacing: -0.0949999988079071px;
  text-align: left;
}

.footercontent a {
  font-family: "Crimson Pro";
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  white-space: nowrap;
}

.footercontent div {
  border-right: 1px solid #d1d1d1;
  padding-left: 40px;
}

.footercontent div:nth-child(4) {
  border: none;
}

.boyimg {
  width: 100%;
}

.pandpcontent h5 {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.pandpcontent p {
  font-family: "Inter";
  font-size: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  color: #666666;
}

.PrivacyandPolicy {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/ima (2).png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* Adjust this based on your layout */
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.852);
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.banner-1img {
  width: 970px;
  height: 100%;
}

.OurFeaturesmain {
  background-color: #8ee3bb;
}

.OurFeaturesmain h2 {
  padding-top: 60px;
  font-family: "Montserrat";
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -0.25px;
  text-align: left;
}

.OurFeaturesmain p {
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 30.06px;
  text-align: left;
}

.OurFeaturesmain a {
  margin-top: 30px;
  padding: 4px 31.86px 9px 32px;
  gap: 0px;
  font-family: "Crimson Pro";
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  background: #171717;
  color: white;
}

.banner-1>p:nth-child(2) {
  padding-top: 20px;
  font-size: 70px;
  font-family: "Montserrat";
  font-weight: 600;
}

.minibox4 {
  width: 40%;
}

@media screen and (max-width: 1200px) {
  .banner-1>p:nth-child(2) {
    padding-top: 5px;
    font-size: 35px;
  }

  .banner-1>p:nth-child(4) {
    padding-top: 10px;
  }

  .banner-1 a {
    margin-top: 10px;
  }

  .banner-1>p:nth-child(3) {
    margin-top: 20px;
    font-size: 15px;
    width: 80%;
    font-family: "Nunito Sans";
  }

  .banner-2-mini1 {
    padding-left: 50px;
  }
}

@media screen and (max-width: 700px) {
  .loginpage input {
    width: 100%;
    padding: 10px !important;
    gap: 8px;
    border-radius: 12px;
    border: 1px 0px 0px 0px;
    opacity: 1px;
    border: 1px solid #c5c6cc;
  }

  .minibox1 {
    width: 0%;
  }

  .minibox3 {
    width: 100%;
  }

  .minibox2 {
    width: 100%;
  }

  .minibox4 {
    width: 100%;
  }

  .feedbox {
    top: 10px;
    background-color: #ffe399;
    width: 85%;
    height: 47%;
    bottom: 200px;
    left: 10px;
    padding: 20px;
    gap: 0px;
    position: absolute;
    z-index: 0 !important;
    font-family: "Montserrat";
    font-size: 34px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .testimonialsimage {
    z-index: 1 !important;
    position: relative;
    left: 50px;
    top: 50px;
    height: 100%;
    width: 90%;
    margin-bottom: 70px;
  }

  .PrivacyandPolicy {
    height: 180px;

  }

  .aboutus p {
    padding-top: 20px;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 19.06px;
  }
  .aboutus h2 {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .linehr {
    display: none;
    border: none;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.218);
    position: relative;
    top: 0px;
  }

  .testimonialscontent p {
    margin-top: 50px;
    font-family: "Nunito Sans";
    font-size: 13.1px;
    font-weight: 400;
    line-height: 22.28px;
    text-align: left;
    width: 313px;
    height: 112px;
    top: 4290px;
    left: 31px;
    gap: 0px;
  }

  .OurFeaturesmain h2 {
    width: 333px;
    height: 56px;
    font-size: 38px;
    text-align: left;
  }

  .OurFeaturesmain p {
    padding-top: 30px;
    font-size: 14.37px;
    font-weight: 400;
    line-height: 23.99px;
    text-align: left;
    width: 300px;
  }

  .OurFeaturesmain a {
    width: 130.89px;
    height: 42.3px;
    top: 1885.05px;
    left: 18px;
    padding: 3.19px 25.43px 7.11px 25.54px;
    gap: 0px;

    white-space: nowrap;
  }

  .footercontent {
    border-top: 0px solid #d1d1d1;
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .banner-1 {
    /* padding: 70px; */
    background-image: url("../images/bg-1.png");
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
  }

  .banner-1>p:nth-child(1) {
    font-size: 20px !important;
    font-family: "Crimson Pro";
  }

  .banner-1>p:nth-child(2) {
    width: 202px;
    height: 103px;
    font-size: 40px;
    letter-spacing: -1px;
    text-align: left;
  }

  .banner-1>p:nth-child(3) {
    padding-top: 10px;
    padding-top: 12px;
    font-size: 10px;
  }

  .banner-1>p:nth-child(4) {
    font-family: "Manrope";
    font-weight: 700;
    font-size: 20px;
    padding-top: 10px;
  }

  .banner-1>img:nth-child(1) {
    width: 220px;
    height: 66px;
    margin-bottom: 40px;
  }

  .banner-1 a {
    margin-bottom: 20px;
    white-space: nowrap;
  }

  .banner-2-mini1 {
    background-color: #8ee3bb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;
    height: 100%;
    padding: 50px 20px;
    padding-bottom: 20px;
  }

  .oneline p {
    font-family: "Montserrat";
    font-size: 10px;
    font-weight: 500;
    color: white;
    padding: 10px 5px;
  }

  .banner-2-mini1 p {
    font-size: 12px;
    padding: 10px 0px;
  }

  .banner-2-mini1 h3 {
    font-size: 21px;
  }

  .banner-2-mini1 p:nth-child(3) {
    font-family: "Crimson Pro";
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .boyimg {
    width: 100%;
  }

  .footercontent div {
    border: none;
    padding-left: 10px;
  }

  .OurFeatures {
    background-color: #8ee3bb;
    overflow: hidden;
    padding: 10px;
  }

  .OurFeatures2 {
    overflow: scroll;
  }

  .OurFeatures button {
    width: 100%;
    justify-content: center;
  }

  .OurFeatures2 div div {
    padding-left: 10px;
    border-right: 0px solid rgba(0, 0, 0, 0.329);
  }

  .OurFeatures2 p:nth-child(2) {

    padding-top: 10px;
    font-family: "Montserrat";
    font-size: 17px;
    font-weight: 600;
    line-height: 19.9px;
    letter-spacing: -0.11500000208616257px;
    text-align: left;
  }

  .mailtext {
    font-size: 18px !important;
  }

  .OurFeatures2 p:nth-child(3) {
    font-size: 18px;
  }

  .OurFeatures2 p:nth-child(4) {
    font-size: 21px;
    font-style: italic;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
  }

  .ourblogs h2:nth-child(1) {
    font-size: 28px;
  }

  .cardcontent h2 {
    font-size: 13px;
    line-height: 20.71px;
  }

  .cardcontent p {
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: 400;
    line-height: 20.71px;
    text-align: left;
  }

  .cardcontent button {
    width: 105px;
    height: 40px;
    top: 4209.41px;
    left: 239px;
    gap: 0px;
    border-radius: 4px;

    background: #000000;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: white;
  }

  .pandpcontent h5 {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.25px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .pandpcontent p {
    font-family: "Inter";
    font-size: 12px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #666666;
  }
}

@media screen and (max-width:1000px) {
  .banner-1 a {
    margin-top: 10px;
  }

  .banner-1>p:nth-child(4) {
    padding: 10px 0px;
  }


}






.taskpagecon {
  background-color: #eaf2ff;
  padding: 20px;
}

.taskpagecon .div {
  background-color: #ffffff;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
}

.taskpagecon div .taskpagehead {
  color: #ffffff;
  background-color: #006ffd;
  padding: 8px 18px;
  border-radius: 10px;
  font-weight: 900px;
  font-family: "Inter";
}

.taskpagecon div .taskpagehead2 {
  color: #828282;
  border-radius: 10px;
  font-family: "Inter";
  font-weight: 800px;
}
.maintaskpage h5 {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  padding: 15px 0px;
}
.maintaskpage h6 {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  padding: 15px 0px;
}

.maintaskpage p {
  color: #71727a;
  font-family: "Inter";
  font-size: 13px;
  padding: 15px 0px;
}

.loginpage input {
  width: 100%;
  padding: 15px 20px;
  gap: 8px;
  border-radius: 12px;
  border: 1px 0px 0px 0px;
  opacity: 1px;
  border: 1px solid #c5c6cc;
}

.paybox {
  background-color: #eaf2ff;
  padding: 20px;
  border-radius: 16px;
}

.starbox {
  background-color: #f8f9fe;
  border-radius: 14px;
  padding: 10px;
}

.starbox p {
  padding: 10px 0px;
  color: #71727a;
  font-size: 13px;
}

.staricon {
  position: absolute;
  top: 4px;
  right: 10px;
  /* left: 10px; */
}

.paybox span:nth-child(1) {
  color: #006ffd;
  font-size: 12px;
}

.loginpage button {
  border-radius: 12px;
}

.input-field {
  width: 100%;
  padding: 15px 20px;
  border-radius: 12px;
  border: 1px solid #c5c6cc;
  margin-bottom: 10px;
}

.input-field:focus {
  outline-color: #006ffd;
}

.input-button {
  border-radius: 12px !important;
}

.input-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.succestick {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.succestick img {
  animation: zoomInOut 5s infinite alternate;
  transform: scale(1) rotateY(0deg);
}

.finishedmsg img {
  animation: zoomInOut 5s infinite alternate;
  transform: scale(1) rotateY(0deg);
}

@keyframes zoomInOut {
  0% {
    transform: scale(1) rotateY(0deg);
  }

  50% {
    transform: scale(0.5) rotateY(180deg);
  }

  100% {
    transform: scale(1) rotateY(360deg);
  }
}

.file-select-button {
  border-left: 1px solid rgba(0, 0, 0, 0.174) !important;
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Inter";
  font-weight: 400;
  text-align: left;
  color: #c5c6cc;
  padding: 17px 21px;
  background: #f4f4f4;
  color: #8f9098;
  border: none;
  border-radius: 0 12px 12px 0;
  cursor: pointer;
}

.file-input {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.or-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  /* Adjust as needed */
}

.or-line {
  flex: 1;
  height: 1px;
  background-color: #0000001f;
}

.or-text {
  margin: 0 10px;
  /* Adjust as needed */
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.taskcontent {
  padding: 10px;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.taskcontent h2 {
  font-family: "Inter";
  font-size: 28px;
  font-weight: 700;
  padding: 20px 0px 10px 0px;
}

.taskcontent2 {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 20px;
}

.taskcontent2 .linebox {
  background-color: #bbbbbb49;
  height: 15px;
  margin: 0px 10px;
  border-radius: 20px;
  position: relative; /* Position relative to place the icon inside */
}

.taskcontent2 .linebox2 {
  border-radius: 20px;
  height: 15px;
  width: 50%; /* Default width; will be overridden by inline style */
}

.person-icon {
  position: absolute;
  top: -40px; /* Adjust as needed to position the icon above the line */
  left: 0; /* Default positioning */
  width: 45px; /* Adjust the size of the icon */
  height: 45px; /* Adjust the size of the icon */
}


.tastsubbtn button:nth-child(1) {
  border-radius: 5px;
  border: 2px solid #006FFD;
  color: #006FFD;
}

.tastsubbtn button:nth-child(1):hover {
  color: rgb(255, 255, 255);
  border: 2px solid #006FFD;

}

.tastsubbtn button:nth-child(2) {
  border-radius: 5px;
}

.taskcontent p:nth-child(2) {
  color: #71727a;
  padding-bottom: 20px;
}

.tasksubcontent h2 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0px 10px 0px;
}

.tasksubcontent p {
  color: #71727a;
  padding-bottom: 20px;
}




.daysection h6 {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 800;
  padding-bottom: 10px;
}

.daysection div {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* scrollbar-width: none;  */
}

.daysection div {
  /* Hide scrollbars for Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  /* Hide scrollbars for WebKit based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Enable scrolling */
  overflow-y: auto; /* Enable vertical scrollbar only when necessary */
  max-height: 100%; /* Adjust the maximum height as per your layout */
}

.cmtsection {
  background-color: #F8F9FE;
  border-radius: 20px;
  padding: 10px 20px;
}

.cmtsection p:nth-child(1) {
text-transform: uppercase;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 700;
  color: #71727A;
}

.cmtsection p:nth-child(2) {
  font-family: "Inter";
  font-size: 15px;
  padding: 10px 0px;
}

.cmtsectionhead {
  font-family: "Inter";
  font-size: 20px;
  padding-bottom: 20px;
  font-weight: 700;
}

.cmtsection span {
  font-family: "Inter";
  font-size: 12px;
  padding: 20px 0px;
  font-weight: 700;
  color: #71727A;

}

.Choosefile {
  background-color: rgba(128, 128, 128, 0.232);
  height: 100%;
  padding: 15px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  
}

.cmtsectioninputs textarea {
  padding: 20px;
  width: 100%;
}


.cmtsectionfile {
  background-color: #006ffd;
  border-radius: 20px;
  width: fit-content;
  padding: 0px 10px;
  font-size: 15px;
  color: white;
  font-weight: 600;
}

.unselectedday {
  background-color: #EAF2FF;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 20px;
  color: #828282;
  letter-spacing: 1px;
}
.completedday {
  background-color: #00b023 !important;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 20px;
  color: white;
  letter-spacing: 1px;
  }

.selectedday {
  background-color: #006ffd;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 20px;
  color: white;
  letter-spacing: 1px;

}

.subtasksection h6 {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
}

.subtasksection p {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #71727a;
  padding-top: 10px;
}
.myaccount h3{
  font-family: "Inter";
  font-size: 23px;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.myaccount label {
  font-family: "Inter";
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
}

.logoutpopup h6 {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 900;
  line-height: 19.36px;
  letter-spacing: 0.005em;
  text-align: center;
}

.logoutpopup p {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #71727a;
  padding: 20px 5px;
}

.logoutbuttons button:nth-child(1) {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #006ffd;
  width: Fill (130px) px;
  height: Fixed (40px) px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 12px;
  border: 1.5px 0px 0px 0px;
  opacity: 0px;
  border: 1.5px solid #006ffd;
}

.logoutbuttons button:nth-child(2) {
  font-family: "Inter";
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  background-color: #006ffd;
  width: Fill (130px) px;
  height: Fixed (40px) px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 12px;
  border: 1.5px 0px 0px 0px;
  opacity: 0px;
  border: 1.5px solid #006ffd;
}

.footerbtns button {
  border: 2px solid #0300c000;
  border-radius: 100%;
  padding: 0px 8px;
  background-color: #006ffd;
}


.popup-banner {
  position: fixed;
  top: 50%;
  right: 50px; /* Adjust this value to position the banner */
  transform: translateY(-50%);
  background-color: #398ffffb; /* Yellow background color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  animation: waveEffect 1s ease-in-out; /* Apply wave effect animation */
}

.banner-content {
  color: #000000;
  font-family: "Inter";
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 20px;
}

@keyframes waveEffect {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.id_cardcon {
  position: relative;
  border: 1px solid #ccc;
  /* padding: 11px; */
  border-radius: 12px;
}

.id_cardfield {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-label {
  display: inline-block;
  background-color: #cccccc89; /* Gray background color */
  padding:13px;
  border-radius: 12px 0px 0px 12px;
  cursor: pointer;
}
.logoutbtns button:nth-child(1){
  background-color: #0020d2aa;
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  font-weight: 500;
}
.logoutbtns button:nth-child(2){
  background-color: #d20000aa;
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  font-weight: 500;
}
.logoutbtns button:nth-child(1):hover{
  background-color: #0020d2d9;
}
 
  .logoutbtns button:nth-child(2):hover{
    background-color: #d20000dd;
  }

.editbutton{
background-color: blue;
padding: 10px 15px;
border-radius: 100%;
}
.com-btn button:nth-child(2){
font-size: 8px;
white-space: nowrap;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.completedbutton{
background-color: rgb(0, 183, 0);
padding: 10px 15px;
border-radius: 100%;
}
.editbutton2{
background-color: rgba(255, 8, 8, 0.775);
padding: 10px 15px;
border-radius: 100%;
}
.editbutton2:hover{
background-color: rgba(255, 43, 43, 0.648);
padding: 10px 15px;
border-radius: 100%;
}
.editbutton1{
background-color: rgba(111, 111, 111, 0.673);
padding: 10px 20px;
border-radius: 100%;
color: white;
}
.signout{
font-weight: 600;
color: rgba(255, 255, 255, 0.902);
background-color: red;
    padding: 4px 8px;
    border-radius: 10px;
    font-size: 15px;
    
    font-family: "Inter";
}
.signout:hover{
background-color: rgba(255, 0, 0, 0.523);

}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate-slide-in {
  animation: slideIn 1s ease-out;
}

.fade-out {
  animation: fadeOut 1s ease-out forwards;
}
.trophyimag{
display: flex;
justify-content: end;
align-items: center;
position: relative;
top: 0px;
}
/* Define the slide animation */
@keyframes slideIn {
  0% {
    left: -50px; /* Start position off-screen to the left */
  }

}

/* Define the continuous wave effect */
@keyframes continuousWave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Adjust this value to control the wave height */
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply the animations to the image */

.runnimg  {
  animation: slideIn 2s ease-in-out forwards, continuousWave 2s ease-in-out infinite; /* Adjust timings as needed */
}
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: transparent;
  border: 2px solid #d1d5db;
  border-radius: 9999px; /* Full rounded */
  transition: all 0.3s ease-in-out;
  position: relative;
}

.custom-checkbox:checked {
  width: 1.5rem;
  height: 1.5rem;
  border-color: #10b981; /* green border */
}

.custom-checkbox:checked::before {
  content: '✓';
  display: block;
  color: #10b981; /* green checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
}

.custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.5); /* green focus ring */
}
.medals{
display: flex;
flex-wrap: wrap; 
align-items: center;
}

.medals span:nth-child(1){
  padding: 3px 10px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 20px;
  letter-spacing: 1px;
display: flex;
align-items: center;
gap: 7px;
font-weight: 800 !important;
}
.medals span:nth-child(2){
  padding: 3px 10px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 20px;
  letter-spacing: 1px;
display: flex;
align-items: center;
gap: 7px;
font-weight: 800 !important;

}
.medals span:nth-child(3){
  padding: 3px 10px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 20px;
  letter-spacing: 1px;
display: flex;
align-items: center;
gap: 7px;
font-weight: 800 !important;

}
.medals span:nth-child(4){
  padding: 3px 10px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 20px;
  letter-spacing: 1px;
display: flex;
align-items: center;
gap: 7px;
font-weight: 800 !important;

}
@keyframes rotateAnimation {
  0% {
    transform: rotate(-30deg); /* Start position */
  }
  50% {
    transform: rotate(0deg); /* Midpoint */
  }
  100% {
    transform: rotate(-30deg); /* End position (loops back to start) */
  }
}

.badgerotate {
  animation: rotateAnimation 3s ease-in-out infinite; /* Adjust duration as needed */
  position: relative;
  right: 6px;
}
.familytask span{
  font-size: 14px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.familytaskcontent p{
  font-size: 14px;
  line-height: 30px;
}
.message{
  color: rgb(106, 106, 106);
  font-size: 18px;
  line-height: 30px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;

}
@media screen and (max-width:700px) {
  .popup-banner {
display: none;
  }
  .msgtwolevel h3{
    font-size: 14px;
  }
 .medals {
        flex: 1 1 50%; /* Two items per row */
    }
 .medals span{
        margin-bottom: 10px;
        justify-content: center;
    }

.trophyimag{
display: flex;
justify-content: end;
align-items: center;
position: relative;

}
.taskcontent h2{
font-size: 19px;
}
.taskpagecon div .taskpagehead2 {
font-size: 13px;
}
.message{
  color: rgb(106, 106, 106);
  font-size: 12px;
  line-height: 10px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;

}
}